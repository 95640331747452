
import { defineComponent, computed, ref } from "vue";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Pagination from "@/components/Pagination.vue";
import {
  isUndefined,
  useQuery,
  useMutateQuery,
  useHasRole
} from "@/core/helpers/common-helper";
import { convertToFullDateTimeWithoutSecond } from "@/core/helpers/date-helper";
import TableSkeleton from "@/components/skeleton/Table.vue";
import FilterBar from "./filter-bar.vue";
import { useI18n } from "vue-i18n";
import useAccountList from "@/core/services/compositions/account/useAccountList";

export default defineComponent({
  components: {
    Pagination,
    TableSkeleton,
    FilterBar
  },
  setup() {
    // init
    const { t } = useI18n();

    // breadcrumb
    setNewPageBreadcrumbs(t("account.title"), [{ title: t("account.title") }]);

    const paginationRef = ref(null);
    const { query } = useQuery();
    const fetchParams = computed(() => ({
      page: query.value.page || 1,
      limit: query.value.limit || 20,
      q: query.value.q
    }));
    const { data, isValidating } = useAccountList(() => fetchParams.value);

    const { mutateQuery } = useMutateQuery();
    const handleChangePage = (nextPage: number) => {
      mutateQuery({
        page: nextPage
      });
    };

    const searchInput = ref(query.value.q || "");
    const onSearch = () => {
      mutateQuery({
        page: 1,
        search: searchInput.value
      });
    };

    const roleUtils = useHasRole();

    return {
      ...roleUtils,
      data,
      isValidating,
      isUndefined,
      convertToFullDateTimeWithoutSecond,
      searchInput,
      onSearch,
      handleChangePage,
      paginationRef
    };
  },
  methods: {}
});
