
import { defineComponent, ref, watch } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import {
  useQuery,
  useMutateQuery,
  useHasRole
} from "@/core/helpers/common-helper";

export default defineComponent({
  components: {
    BaseButton
  },
  setup() {
    const { query } = useQuery();
    const { mutateQuery } = useMutateQuery();
    const searchQuery = ref(query.value.q || "");

    watch(
      () => query.value.q,
      val => {
        searchQuery.value = val;
      }
    );

    const handleSearch = () => {
      mutateQuery({
        page: 1,
        q: searchQuery.value
      });
    };

    const roleUtils = useHasRole();

    return {
      ...roleUtils,
      searchQuery,
      handleSearch
    };
  }
});
